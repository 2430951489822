<template>
  <div class="connections">
    <div class="connections__welcome" v-if="!isStarted">
      <div class="connections__welcome-title">Связь</div>
      <div class="connections__welcome-subtitle">поколений</div>
      <div class="connections__button">
        <a href="javascript:void(0)" @click="initSlider">начинаем</a>
      </div>
    </div>
    <div class="connections__slider" v-else>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/connections-image-1.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="connections__nav">
        <div class="connections__nav-arrow" @click="slidePrev"></div>
        <div class="connections__nav-text">{{ index }}/9</div>
        <div
          class="connections__nav-arrow connections__nav-arrow--last"
          @click="slideNext"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "Connections",
  data() {
    return {
      slider: null,
      isStarted: false,
      index: 1,
    };
  },
  methods: {
    initSlider() {
      this.isStarted = true;
      let that = this;
      setTimeout(() => {
        this.$emit("change");
        this.slider = new Swiper(".swiper", {
          direction: "horizontal",
          slidesPerView: 1,
          loop: false,
          on: {
            slideChange: function () {
              that.index = this.realIndex + 1;
            },
          },
        });
      }, 100);
    },
    slideNext() {
      this.slider.slideNext();
    },
    slidePrev() {
      this.slider.slidePrev();
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.connections
  display: block
  width: 100%
  height: 100%
  position: relative
  @media screen and (max-width: 1000px)
    height: vwm(336px)
  &__nav
    width: vw(171px)
    height: vw(40px)
    display: flex
    align-items: center
    justify-content: space-between
    position: absolute
    color: #FF4F12
    font-family: 'Tektur'
    font-size: vw(24px)
    position: absolute
    bottom: vw(78px)
    left: 0
    right: 0
    margin: auto
    z-index: 30
    @media screen and (max-width: 1000px)
      width: vwm(171px)
      height: vwm(40px)
      font-size: vwm(24px)
      bottom: vwm(20px)
    &-arrow
      width: vw(50px)
      height: vw(40px)
      background-image: url(../assets/img/arrow-slider.svg)
      background-size: contain
      cursor: pointer
      background-repeat: no-repeat
      background-position: 50% 50%
      @media screen and (max-width: 1000px)
        width: vwm(50px)
        height: vwm(40px)
      &--last
        transform: rotate(180deg)
  &__slider
    display: flex
    height: 100%
    align-items: center
    z-index: 1
    img
      width: 100%
      height: auto
  &__button
    display: flex
    align-items: center
    justify-content: center
    margin-top: vw(-7px)
    @media screen and (max-width: 1000px)
      margin-top: vw(-10px)
    a
      width: vw(220px)
      height: vw(51px)
      background-color: #FF4F12
      text-align: center
      font-family: 'Tektur'
      font-weight: 500
      line-height: vw(51px)
      color: #fff
      font-size: vw(16px)
      letter-spacing: vw(1px)
      border-radius: vw(2px)
      backdrop-filter: blur(12.5px)
      filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
      text-transform: uppercase
      @media screen and (max-width: 1000px)
        width: vwm(206px)
        height: vwm(45px)
        line-height: vwm(45px)
        font-size: vwm(16px)
  &__welcome
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    background-image: url(../assets/img/connections-welcome.jpg)
    display: flex
    flex-direction: column
    &-title
      font-size:  vw(106px)
      color: #fff
      opacity: 0.9
      margin-top: vw(283px)
      font-family: 'Great Vibes'
      margin-left: vw(64px)
      @media screen and (max-width: 1000px)
        font-size: vwm(58px)
        margin-left: vwm(27px)
        margin-top: vwm(134px)
    &-subtitle
      font-size:  vw(106px)
      color: #fff
      opacity: 0.9
      font-family: 'Great Vibes'
      margin-top: vw(-78px)
      margin-left: vw(233px)
      @media screen and (max-width: 1000px)
        font-size: vwm(58px)
        margin-left: vwm(122px)
        margin-top: vwm(-43px)

  &:after
    content: ''
    display: block
    touch-action: none
    position: absolute
    pointer-events: none
    left: 0
    top: 0
    right: 0
    background-image: url('../assets/img/connections_bg.png')
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    z-index: 10
</style>
