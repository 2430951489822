var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup__bg"},[_c('div',{staticClass:"popup w-1140"},[_c('div',{staticClass:"popup__container"},[_c('div',{staticClass:"popup__interactive"},[_c('Connections',{on:{"change":function($event){_vm.isStarted = true}}})],1),_c('div',{staticClass:"popup__article",attrs:{"data-lenis-prevent":""}},[(!_vm.isStarted)?_c('div',{staticClass:"popup__article-content"},[_c('div',{staticClass:"popup__title",class:{
              'popup__title--small': _vm.activeContent.small_title,
              'popup__title--mini': _vm.activeContent.mini_title,
              'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
              'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
            },domProps:{"innerHTML":_vm._s(_vm.activeContent.title)}}),_c('div',{staticClass:"popup__subtitle",domProps:{"innerHTML":_vm._s(_vm.activeContent.info)}}),_c('div',{staticClass:"popup__text",domProps:{"innerHTML":_vm._s(_vm.activeContent.text)}})]):_c('div',{staticClass:"popup__article-content"},[_c('div',{staticClass:"popup__title",class:{
              'popup__title--small': _vm.activeContent.small_title,
              'popup__title--mini': _vm.activeContent.mini_title,
              'popup__title--small-mobile': _vm.activeContent.small_title_mobile,
              'popup__title--mini-mobile': _vm.activeContent.mini_title_mobile,
              'popup__title--step-2': _vm.isStarted,
            }},[_vm._v(" Константин "),_c('br'),_vm._v("и Евгений Петрушевские ")]),_c('div',{staticClass:"popup__text popup__text--custom"},[_vm._v(" Константин Петрушевский начал работу в Ростелекоме 20 лет назад, когда компания активно развивала инфраструктуру связи. Его привлекли масштабные проекты и стабильность. Он быстро поднялся по карьерной лестнице, став старшим управляющим отдела подключений. Евгений, вдохновленный успехом отца и его преданностью делу, решил тоже присоединиться к компании. В отделе продаж он нашел свое призвание, помогая клиентам выбирать лучшие решения. Отец и сын продолжают работать в Ростелекоме, потому что ценят стабильность, команду и возможность расти вместе с компанией. ")])])]),_c('div',{staticClass:"popup__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span')])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }