var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-scroll",class:{ active: _vm.active },attrs:{"id":"cursor"}},_vm._l((_vm.imageList),function(image,index){return _c('div',{key:index,class:[
      'scroll',
      `scroll-${index + 1}`,
      index + 1 === Math.ceil(_vm.activeIndex) ? 'activeScreen' : '',
    ],style:({
      backgroundImage: `url(${image.src})`,
      top: `${index * 996}px`,
      opacity: index === 0 ? '1' : '0',
    }),attrs:{"id":[`screen-${index + 1}`]}},[(index > _vm.activeIndex - 1 && index < _vm.activeIndex + 1)?_c('div',{class:['el', `el-${index + 1}`],attrs:{"data-index":index,"data-active":_vm.activeIndex},on:{"click":function($event){return _vm.$emit('open', `${index + 1}-1`)}}}):_vm._e(),(index > _vm.activeIndex - 1 && index < _vm.activeIndex + 1)?_c('div',{class:['el', `el-${index + 1}-2`],on:{"click":function($event){return _vm.$emit('open', `${index + 1}-2`)}}}):_vm._e(),(index > _vm.activeIndex - 1 && index < _vm.activeIndex + 1)?_c('div',{class:['el', `el-${index + 1}-3`],staticStyle:{"opacity":"0"},on:{"click":function($event){return _vm.$emit('open', `${index + 1}-3`)}}}):_vm._e(),(index === 0)?_c('div',{staticClass:"el el-1-4",on:{"click":function($event){return _vm.$emit('open', `1-4`)}}}):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }