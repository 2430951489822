<template>
  <div class="popup__bg">
    <div class="popup w-1140" :class="{ opened: step == 2 }">
      <div class="popup__container">
        <div class="popup__interactive" v-if="step == 1">
          <div class="histories">
            <div class="histories__button">
              <a href="javascript:void(0)" @click="open">Начать</a>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 1">
          <div class="popup__article-content" v-if="step == 1">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text" v-html="activeContent.text"></div>
          </div>
        </div>
        <div class="histories__container dragscroll" v-if="step == 2">
          <div class="histories__content">
            <div class="histories__column">
              <div class="history">
                <div class="history__title">Стремление к идеалу</div>
                <div class="history__text">
                  <p>
                    В «Ростелекоме» есть много причин, чтобы гордиться своим местом
                    работы. Один из ключевых факторов — это возможность участвовать в
                    крупных и инновационных проектах. Я горжусь тем, что стал частью
                    команды, которая занимается организацией горячей линии 122, и затем
                    возглавил проект по внедрению голосового помощника в эту службу. Это
                    были сложные и интересные задачи, требующие не только глубоких
                    технических знаний, но и умения работать в команде, управления
                    проектами и принятия решений под давлением времени.
                  </p>
                  <p>
                    «Ростелеком» — это возможность внедрения передовых технологий. Я был
                    одним из первых в Сибирском округе, кто внедрил видеоаналитику и
                    подключил телевидение в гостиницы по технологии DVB-C. Это было
                    большое достижение, которое показало, что мы способны быстро
                    адаптироваться к изменениям в мире технологий.
                  </p>
                  <p>
                    Я также испытываю большую радость от возможности тестировать новые
                    технологии, такие как умная каска и мониторинг ТКО. Эти проекты
                    позволяют мне расширять свои знания и навыки, а также понять, какие
                    новые возможности открываются перед нашей компанией.
                  </p>
                  <p>
                    Работа в «Ростелекоме» также предоставляет множество возможностей для
                    саморазвития. Компания предлагает множество курсов и программ, которые
                    помогают сотрудникам развиваться в своих профессиональных навыках. Я
                    благодарен за такие инструменты, как Онлайн-Университет и iMBA,
                    которые позволяют мне продолжать обучаться и развиваться вместе с
                    компанией.
                  </p>
                  <p>
                    Но самое важное — это люди, с которыми я работаю. Они настоящие
                    профессионалы своего дела, и я горжусь тем, что могу быть частью такой
                    талантливой и преданной команды. Мы всегда готовы поддерживать друг
                    друга и вместе преодолевать любые вызовы.
                  </p>
                  <p>
                    Также хочу отметить профсоюзную организацию, которая помогает нам
                    объединяться и бороться за достойный труд.
                  </p>
                  <p>
                    В целом, моя работа в «Ростелекоме» — это постоянное стремление к
                    совершенству, развитие новых навыков и участие в крупных проектах. Я
                    гордый член этой команды и уверен, что вместе мы можем достичь великих
                    вещей.
                  </p>
                </div>
                <div class="history__bottom">
                  Сопко Дмитрий Викторович <br /><span>Иркутский филиал</span>
                </div>
              </div>
              <div class="history">
                <div class="history__title">Ковид не помеха общению</div>
                <div class="history__text">
                  <p>
                    Это недавняя, многим известная ситуация с COVID-19... Когда ввели
                    ограничения на перемещение по городу, людям была нужна связь друг с
                    другом, с родными и близкими. Соседская бабушка нуждалась в постоянном
                    внимании медиков, а сотрудники нашей компании безотлагательно провели
                    ей домашний интернет, тем самым облегчив ее жизнь и самочувствие.
                  </p>
                  <p>
                    Женщина смогла также общаться по Skype с внуками и радовалась новой
                    технологии.
                  </p>
                </div>
                <div class="history__bottom">
                  Панкратова Елена Борисовна <br /><span>Северо-Осетинский филиал</span>
                </div>
              </div>
            </div>
            <div class="histories__column">
              <div class="history">
                <div class="history__title">На рубеже веков</div>
                <div class="history__text">
                  <p>
                    История из жизни кабельщиков на рубеже веков, примерно 1999-2001 года.
                    Сотрудникам выдавали пейджеры для общения и передачи важных рабочих
                    сообщений. Руководитель отправил бригаду электромонтеров устранить
                    повреждение телефонной канализации. Ребята приехали на линию и
                    увидели, что повреждена асбестовая труба. Трубы нужно было получить на
                    складе. Не увидев труб на складе, сотрудник отправил через оператора
                    сообщение: «Мы на месте, не можем найти трубу». А руководитель получил
                    сообщение: «Мы на месте, не можем найти труп».
                  </p>
                </div>
                <div class="history__bottom">
                  Долматов Юрий Владимирович <br /><span>Карельский филиал</span>
                </div>
              </div>
              <div class="history">
                <div class="history__title">Жизненный путь</div>
                <div class="history__text">
                  <p>
                    Работаю в «Ростелекоме» с 1986 года. Весь мой жизненный путь связаны с
                    этой профессией. Я очень благодарна своему коллективу за поддержку и
                    понимание. Я горжусь своей компанией! Я люблю «Ростелеком»!
                  </p>
                </div>
                <div class="history__bottom">
                  Жданкова Валентина Александровна <br /><span
                    >Филиал в Республике Мордовия</span
                  >
                </div>
              </div>
              <div class="history">
                <div class="history__title">Настоящая связь</div>
                <div class="history__text">
                  <p>
                    Когда-то давным-давно, в 2010 году, на территории Можгинского района
                    произошел обрыв кабеля при строительстве дороги. Мой папа работал
                    тогда начальником линейного цеха (сейчас он пенсионер), и ему как раз
                    необходимо было выехать на это повреждение. На тот момент в отделе
                    строительства Администрации Можгинского района работал молодой парень,
                    который согласовывал проекты по земляным работам в Телекоме. Именно он
                    потом и рассказал мне всю эту историю, будучи уже моим мужем. :)
                  </p>
                  <p>
                    Тракторист ему тогда так и сказал: «О, сейчас приедет Тулупов —
                    отматерит тебя, что без разрешения начали копать. Не хотел бы я с ним
                    встречаться.»
                  </p>
                  <p>
                    В организациях, специализирующихся на строительстве коммуникаций и
                    объектов, знали моего папу как настоящего связиста, переживающего за
                    нашу связь. И я этим гордилась. Да, тогда мой папа приехал на
                    повреждение, и тому молодому парню досталось по полной. :) Вот так они
                    первыми и познакомились.
                  </p>
                  <p>
                    В конце 2010 года я поступила на работу в Телеком в качестве
                    паспортиста, и опять же этот молодой парень приходит на согласование.
                    При разговоре выясняется, что он знает моего папу. Вечером этого же
                    дня он находит меня в интернете, и так начинается наша с ним история,
                    которая длится по сей день. Я с 100% уверенностью говорю, что
                    «Ростелеком» нас связал.
                  </p>
                </div>
                <div class="history__bottom">
                  Филиал в Удмуртской Республике <br /><span
                    >Головко Оксана Валерьевна</span
                  >
                </div>
              </div>
              <div class="history">
                <div class="history__title">Поддержка во всём</div>
                <div class="history__text">
                  <p>
                    Хочу высказать слова благодарности компании «Ростелеком» и
                    руководителям от себя, от своей семьи и от всех переселенцев,
                    вынужденно покинувших свои дома, за оказанную моральную и материальную
                    поддержку.
                  </p>
                  <p>
                    Компания всем без исключения оказала помощь с организацией арендного
                    жилья и предоставила рабочие места в г. Курске для своих сотрудников.
                    Для детей членов семьи было предоставлено оборудование для удаленной
                    учебы, одежда, обувь и многое другое — всего не перечислить.
                  </p>
                  <p>
                    Самое главное, нас окружили теплотой и вниманием, чтобы мы не
                    чувствовали себя брошенными и никому не нужными. Такой поддержки не
                    оказала ни одна другая компания для своих сотрудников, попавших в
                    такую трудную ситуацию. «Ростелеком», несмотря на огромные убытки в
                    приграничных районах, не бросил ни своих сотрудников, ни членов их
                    семей. Я горжусь тем, что работаю здесь, и моя семья тоже этим
                    гордится. Большое спасибо! Желаю успеха и процветания!
                  </p>
                </div>
                <div class="history__bottom">
                  Шевченко Иван Иванович <br /><span>Курский филиал</span>
                </div>
              </div>
            </div>
            <div class="histories__column">
              <div class="history">
                <div class="history__title">Вперед, только вперед!</div>
                <div class="history__text">
                  <p>
                    Я горжусь своей любимой компанией Ростелеком, потому что она
                    стремительно развивается, с опережением создает и применяет новые
                    технологии, заботится о сотрудниках и сближает нас с нашими близкими,
                    а также с партнерами по бизнесу благодаря волшебному интернету и
                    телефонной связи.
                  </p>
                  <p>
                    Я благодарна Ростелекому и его руководителям за помощь в трудный
                    период, за интересную работу, за возможность развития, быть всегда в
                    тренде и шагать в ногу со временем!
                  </p>
                  <p>
                    С любовью и гордостью делюсь впечатлениями и рекомендациями о
                    продуктах компании, а также о работе в нашем коллективе.
                  </p>
                  <p>
                    Моя трудовая деятельность в Ростелекоме началась в 2001 году в филиале
                    Кубаньэлектросвязь (тогда еще) «Центр новых технологий». Далее я
                    перешла на повышение в управление «Южная телекоммуникационная
                    компания». Меня заметили как достойного сотрудника и пригласили в
                    новую службу, которая была образована в 2004 году, и от этих истоков
                    «родился» «Отдел материально-технического обеспечения», где с нуля мы
                    с моим новым руководителем и коллегами организовали работу данного
                    отдела.
                  </p>
                  <p>
                    С тех пор наш ОМТО много раз переименовывался, развивался, дорос до
                    Департамента закупок, и в настоящий момент я единственная (из этого
                    отдела), кто преодолел все изменения в компании и работаю по
                    сегодняшний день старшим специалистом Центра компетенции по малым
                    закупкам.
                  </p>
                  <p>
                    Это очень круто, и мне есть чем гордиться, потому что я взрослела и
                    развивалась вместе со всеми, кто был со мной все эти годы. И это
                    замечательно, когда человек движется не обязательно по вертикали, но и
                    по горизонтали вперед и только вперед!
                  </p>
                </div>
                <div class="history__bottom">
                  Безрукавная Светлана Александровна <br /><span>ОЦО</span>
                </div>
              </div>
              <div class="history">
                <div class="history__title">Испытание и преодоление</div>
                <div class="history__text">
                  <p>
                    В 1996-97 годах нас, молодых парней (меня и коллегу Лая Андрея
                    Владимировича), отправили в командировку в Ленинградскую область, г.
                    Тихвин, для обучения сотрудников работе на новом оборудовании.
                    Оборудование было очень дорогое, и мы понимали, что отвечаем за него
                    «головой».
                  </p>
                  <p>
                    Мы остановились в гостинице, и наш номер оказался прямо над
                    рестораном, а в ресторане всю ночь гуляли «братки», и не просто
                    гуляли, а пели, плясали, кричали, дрались, устраивали шумные разборки
                    и прочее. Мы, соответственно, всю ночь не спали, очень переживали за
                    оборудование (не то чтобы его украдут, а то, что поломают или
                    испортят), но и немного за себя.
                  </p>
                  <p>
                    На следующий день мы провели обучение, продемонстрировали оборудование
                    и отправились в обратный путь. Но нужных билетов не оказалось, и нам
                    пришлось ехать до Петрозаводска с пересадкой. Первая часть пути была в
                    общем вагоне от станции Тихвин до станции Волховстрой. Общий вагон был
                    переполнен, заняты были даже третьи полки, были и нетрезвые лица, и
                    подозрительные на наш взгляд люди. И, естественно, мы боялись даже
                    задремать и караулили оборудование.
                  </p>
                  <p>
                    Ночью была пересадка в Волховстрое; на вокзале царила в те времена
                    разруха. Пустой, страшный, без ремонта вокзал, где даже не было
                    туалета, ждали пересадки, не выпуская из рук оборудование.
                  </p>
                  <p>
                    Наконец, мы сели на поезд до Петрозаводска, там было спокойно, мы
                    понимали, что дом близко. Легли на свои полки, и… мы проснулись от
                    того, что нас за плечо трясет проводник и говорит, что мы давно
                    приехали, все пассажиры вышли, и сейчас нас увезут в депо. Мы
                    вскочили, и первым делом взялись проверять оборудование. Оно оказалось
                    на месте!
                  </p>
                  <p>
                    Закончилось все хорошо: мы вышли из поезда и не потеряли драгоценное
                    оборудование.
                  </p>
                </div>
                <div class="history__bottom">
                  Солодкий Игорь Николаевич <br /><span>Карельский филиал</span>
                </div>
              </div>
            </div>
            <div class="histories__column">
              <div class="history">
                <div class="history__title">Рука и сердце</div>
                <div class="history__text">
                  <p>
                    23 сентября 2016 года, в день компании Ростелеком, в Ельцин Центре мой
                    коллега сделал мне предложение руки и сердца! Этот день навсегда
                    останется в нашей памяти, т. к. благодаря Ростелекому мы нашли друг
                    друга.
                  </p>
                </div>
                <div class="history__bottom">
                  Каримова Александра Сергеевна <br /><span>Екатеринбургский филиал</span>
                </div>
              </div>
              <div class="history">
                <div class="history__title">Жизненный путь</div>
                <div class="history__text">
                  <p>
                    Я горжусь своей компанией за то, что она многому научила меня в жизни
                    и дала возможность вырасти от электромонтера до ведущего инженера.
                    Правда, для этого понадобились долгие 30 лет упорной работы.
                  </p>
                </div>
                <div class="history__bottom">
                  Замараев Алексей Викторович <br /><span
                    >Филиал в Удмуртской Республике</span
                  >
                </div>
              </div>
              <div class="history">
                <div class="history__title">Рост и возможности</div>
                <div class="history__text">
                  <p>
                    Свою трудовую деятельность я начала в ПАО «Ростелеком» (ранее —
                    «Электросвязь») оператором вычислительных машин отдела АСУ. До октября
                    1996 года я нигде не работала, с 19 лет работаю в данной компании,
                    взрослея и набираясь опыта.
                  </p>
                  <p>
                    Первые годы работы в компании совмещала с учебой в Балтийском
                    федеральном университете им. Канта в г. Калининграде на факультете
                    «Славянская филология и журналистика». Получив высшее образование, мне
                    захотелось поменять профиль своей работы.
                  </p>
                  <p>
                    В августе 2006 года я рискнула и перешла в отдел делопроизводства
                    компании на декретную должность, будучи уверенной в том, что не
                    останусь без работы после выхода основного работника.
                  </p>
                  <p>
                    В ноябре 2007 года меня перевели на должность инспектора по контролю
                    за исполнением поручений. С февраля 2008 года я возглавляю группу
                    делопроизводства (ранее — канцелярия/отдел делопроизводства).
                  </p>
                  <p>
                    За время работы в компании я успела выйти замуж, родить двух
                    прекрасных и любимых дочерей (21 год и 13 лет). Моя семья постоянно
                    вдохновляет и морально помогает мне в решении многих задач и
                    творческих замыслов.
                  </p>
                  <p>
                    По своей натуре я являюсь коммуникабельным, открытым, инициативным и
                    ответственным человеком, всегда стремлюсь к достижению поставленных
                    целей и приобретению новых навыков и знаний.
                  </p>
                  <p>
                    Работая с 2006 года в отделе делопроизводства, я приобрела новые
                    знания и опыт, а также умение решать важные вопросы. Под
                    профессионализмом я понимаю совокупность знаний и навыков, присущих
                    конкретной специальности или профессии.
                  </p>
                  <p>
                    Я горжусь тем, что работала в исторических предприятиях Ростелекома. В
                    моей трудовой книжке отсутствуют записи о трудовой деятельности в
                    других компаниях.
                  </p>
                  <p>
                    РОСТЕЛЕКОМ — ЭТО СОЦИАЛЬНО-ЗАЩИЩЕННАЯ КОМПАНИЯ, В КОТОРОЙ СУЩЕСТВУЮТ
                    БЕЗГРАНИЧНЫЕ ВОЗМОЖНОСТИ ДЛЯ РАЗВИТИЯ НАШИХ ПРОФЕССИОНАЛЬНЫХ И
                    ЛИЧНОСТНЫХ КАЧЕСТВ.
                  </p>
                  <p>
                    Я считаю, что каждый из сотрудников компании должен осознавать в
                    первую очередь то, что работать удивительно интересно и перспективно!
                    Нужно гордиться и любить свою профессию, так как всё, что делается с
                    любовью, приносит только положительные результаты. Нужно уметь
                    радоваться жизни на работе и дома, всегда совершенствоваться и
                    постоянно учиться, осваивать новые технологии и профессионально
                    развиваться.
                  </p>
                </div>
                <div class="history__bottom">
                  Володина Тамара Борисовна <br /><span>Калининградский филиал</span>
                </div>
              </div>
            </div>
            <div class="histories__column">
              <div class="history">
                <div class="history__title">Новые горизонты</div>
                <div class="history__text">
                  <p>
                    Горжусь своей компанией в том, что в нашем городе появилось новое
                    направление развития сетей GPON. Рад подключению своих горожан к
                    данной сети. Благодаря сети GPON частный сектор будет развиваться и
                    пользоваться спросом.
                  </p>
                </div>
                <div class="history__bottom">
                  Соловьев Алексей Михайлович <br /><span
                    >Филиал во Владимирской и Ивановской областях</span
                  >
                </div>
              </div>
              <div class="history">
                <div class="history__title">Мы — сила</div>
                <div class="history__text">
                  <p>
                    В 2023 году я тяжело заболела лейкозом. Требовалось дорогостоящее
                    лечение. Компания и сотрудники меня поддержали. Благодарю вас за то,
                    что поддержали меня и мою семью в трудное для нас время. Спасибо, что
                    не бросили, а помогли и дали шанс жить дальше. Я увидела, какая мы
                    сила и в каком прекрасном мире мы живем. Здоровья вам и вашим близким.
                    Берегите себя, верьте в чудеса. Компании — роста и процветания.
                  </p>
                </div>
                <div class="history__bottom">
                  Кузнецова Наталья Владимировна <br /><span
                    >Филиал в Брянской и Орловской областях</span
                  >
                </div>
              </div>
              <div class="history">
                <div class="history__title">Плыви, «Аврора»!</div>
                <div class="history__text">
                  <p>
                    Горжусь, что Ростелеком одним из первых в России запустил ОС «Аврора».
                    Я имела непосредственное отношение к тестированию системы, которое
                    проходило во время Всероссийской переписи населения 2020 года.
                    Статистическое управление Челябинской области выдало всем переписчикам
                    планшеты, и по окончании переписи мы писали свои замечания и
                    предложения по работе в приложении ОС «Аврора», а также по работе
                    самих планшетов.
                  </p>
                  <p>
                    В настоящее время, с конца августа, ЦОРК ОЦО приступил к тестированию
                    модели ИИ (искусственный интеллект) для работы с БВ (банковская
                    выписка) филиала МР Волга. Ростелеком не на словах, а на деле
                    показывает доступность ИИ, и этим достижением, вернее, причастностью к
                    данному процессу я тоже горжусь!
                  </p>
                </div>
                <div class="history__bottom">
                  Ермишина Татьяна Владимировна <br /><span>ОЦО</span>
                </div>
              </div>
              <div class="history">
                <div class="history__title">Связь для всех</div>
                <div class="history__text">
                  <p>
                    В мае 2023 года «Ростелеком» в небольшом селе Троицкое Ивановского
                    округа торжественно открыл базовую станцию по нацпроекту УЦН 2.0.
                    Событие действительно значимое для жителей села и, конечно, для самого
                    «Ростелекома». Я принимала участие в открытии, готовила сценарий и
                    праздник для местных жителей. Лично видела, как сельчане радовались,
                    что в селе появилась современная мобильная связь. Они улыбались и
                    приобретали сим-карты.
                  </p>
                  <p>
                    С того момента им не нужно было больше бегать по селу или вовсе
                    выезжать, чтобы поймать хоть какой-то сигнал и отправить элементарное
                    голосовое сообщение. Местный фельдшер одна из первых подключилась к
                    новой связи, чтобы пациенты могли ей позвонить.
                  </p>
                  <p>
                    Символично, что открытие прошло в канун Дня связи. Очень знаковое
                    событие!
                  </p>
                </div>
                <div class="history__bottom">
                  Котельникова Татьяна Олеговна <br /><span>Амурский филиал</span>
                </div>
              </div>
            </div>

            <div class="histories__column"></div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import "dragscroll";
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    open() {
      this.step = 2;
      this.$nextTick(() => {
        const Dragscroll = require("dragscroll");
        Dragscroll.reset();
        const container = this.$el.querySelector(".histories__container");
        container.scrollLeft = (container.scrollWidth - container.clientWidth) / 2;
        container.scrollTop = (container.scrollHeight - container.clientHeight) / 2;
      });
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.histories
	&__manual
		padding-left: vw(58px)
		font-size: vw(13px)
		background-image: url(../assets/img/cursor-histories-helper.svg)
		background-repeat: no-repeat
		background-size: vw(48px)
		background-position: 0 50%
</style>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.history
	width: vw(450px)
	background-color: #fff
	padding: vw(17px) vw(23px) vw(17px) vw(25px)
	margin-bottom: vw(40px)
	border-radius: vw(5px)
	&__title
		color: #FF4F12
		font-size: vw(32px)
		font-family: 'Tektur'
		margin-bottom: vw(5px)
	&__text
		font-size: vw(15px)
		line-height: vw(21px)
		p
			margin-bottom: vw(7px)
	&__bottom
		font-size: vw(13px)
		padding-top: vw(10px)
		span
			color: #919191
.histories
	background-image: url(../assets/img/histories-welcome.png)
	background-size: cover
	width: 100%
	height: 100%
	&__content
		display: block
		display: flex
		padding-top: vw(20px)
		padding-bottom: vw(20px)
		user-select: none
		cursor: url('../assets/img/hand-default.svg') 0 0, auto !important
		&:active
			cursor: url('../assets/img/hand-active.svg') 0 0, auto !important
	&__column
		margin-left: vw(20px)
		margin-right: vw(20px)
		&:empty
			width: 20px !important
			min-width: 20px !important
			margin: 0 !important
		&:first-child
			margin-top: vw(137px)
		&:nth-child(3)
			margin-top: vw(148px)
		&:nth-child(4)
			margin-top: vw(260px)
		&:nth-child(5)
			margin-top: vw(170px)
	&__container
		width: 100%
		height: vw(620px)
		border: 1px solid #FF4F12
		overflow: auto
		&::-webkit-scrollbar
			display: none
	&__button
		display: flex
		align-items: center
		justify-content: center
		position: absolute
		bottom: 0
		width: 100%
		bottom: vw(32px)
		@media screen and (max-width: 1000px)
			margin-top: vwm(9px)
		a
			width: vw(220px)
			height: vw(51px)
			background-color: #FF4F12
			text-align: center
			font-family: 'Tektur'
			font-weight: 500
			line-height: vw(51px)
			color: #fff
			font-size: vw(16px)
			letter-spacing: vw(1px)
			border-radius: vw(2px)
			backdrop-filter: blur(12.5px)
			filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
			text-transform: uppercase
			@media screen and (max-width: 1000px)
				width: vwm(206px)
				height: vwm(50px)
				line-height: vwm(50px)
				font-size: vwm(16px)
.popup__container
	@media screen and (max-width: 1000px)
		padding-left: 0
.w-1140
	width: vw(1048px)
	@media screen and (max-width: 1000px)
		width: 100%
		padding-left: vwm(11.5px)
		padding-right: vwm(7px)
		.popup__title
			font-size: 10.072222vw !important
			line-height: 9.294444vw !important
		.popup__steps
			font-size: vwm(24px) !important
			margin-top: vwm(-4px) !important
			margin-bottom: vwm(7px) !important
		.popup__interactive
			height: vwm(352px)
		.popup__text--custom
			text-align: left
		.popup__title--step-2
			text-align: left
	@media screen and (min-width: 1000px)
		.popup__steps
			font-size: vw(24px) !important
			margin-top: vw(-4px) !important
			margin-bottom: vw(1px) !important
		.popup__article
			margin-left: vw(25px)
			width: vw(700px)
			margin-right: vw(-47px)
		.popup__close
			right: -4.7vw
		.popup__title
			font-size: 3.072222vw
			line-height: 2.894444vw
		.popup__text--custom
			line-height: 1.5
			font-size: vw(15px)
		&.opened
			.popup__close
				right: -1.7vw
</style>
