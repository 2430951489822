<template>
  <div class="popup__bg">
    <div class="popup w-1140">
      <div class="popup__container">
        <div class="popup__interactive">
          <Connections @change="isStarted = true" />
        </div>
        <div class="popup__article" data-lenis-prevent>
          <div class="popup__article-content" v-if="!isStarted">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text" v-html="activeContent.text"></div>
          </div>
          <div class="popup__article-content" v-else>
            <div
              class="popup__title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
                'popup__title--step-2': isStarted,
              }"
            >
              Константин <br />и Евгений Петрушевские
            </div>
            <div class="popup__text popup__text--custom">
              Константин Петрушевский начал работу в Ростелекоме 20 лет назад, когда
              компания активно развивала инфраструктуру связи. Его привлекли масштабные
              проекты и стабильность. Он быстро поднялся по карьерной лестнице, став
              старшим управляющим отдела подключений. Евгений, вдохновленный успехом отца
              и его преданностью делу, решил тоже присоединиться к компании. В отделе
              продаж он нашел свое призвание, помогая клиентам выбирать лучшие решения.
              Отец и сын продолжают работать в Ростелекоме, потому что ценят стабильность,
              команду и возможность расти вместе с компанией.
            </div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import Connections from "@/components/Connections.vue";
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isStarted: false,
    };
  },
  components: {
    Connections,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.w-1140
	width: vw(1048px)
	@media screen and (max-width: 1000px)
		width: vwm(352px)
		.popup__article
			text-align: center
		.popup__text--custom
			text-align: left
		.popup__title--step-2
			text-align: left
	@media screen and (min-width: 1000px)
		.popup__article
			margin-left: vw(25px)
			width: vw(700px)
			margin-right: vw(-47px)
		.popup__close
			right: -4.7vw
		.popup__title
			font-size: 3.072222vw
			line-height: 2.894444vw
		.popup__text--custom
			line-height: 1.5
			font-size: vw(15px)
</style>
