<template>
  <div class="editor">
    <div class="editor__frame">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="editor__welcome" v-if="step == 1">
      <div class="editor__welcome-title"><span>Сила</span></div>
      <div class="editor__welcome-subtitle"><span>команды</span></div>
      <div class="editor__button">
        <a href="javascript:void(0)" @click="changeStep(2)">начинаем</a>
      </div>
    </div>
    <div class="editor__welcome" v-if="step == 2">
      <template v-if="result">
        <div class="blured">
          <img :src="result" alt="" />
        </div>
        <div class="rounded">
          <img :src="result" alt="" />
        </div>
      </template>
      <template v-else>
        <img
          src="@/assets/img/editor-screen-waiting.jpg"
          alt=""
          v-if="!selectedImage || selectedImage.length < 5"
        />
        <template v-else>
          <div class="blured">
            <img :src="selectedImage" alt="" />
          </div>
          <div class="rounded">
            <img :src="selectedImage" alt="" />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhotoEditor",
  data() {
    return {
      step: 1,
    };
  },
  props: {
    selectedImage: {
      type: String,
      default: "",
    },
    result: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeStep(step) {
      this.step = step;
      this.$emit("change", step);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
.blur-main-photo
  .rounded
    filter: blur(vw(3px))
.blured
  filter: blur(vw(7px))
  width: 100%
  height: 100%
  img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center
.rounded
  width: vw(470px)
  height: vw(470px)
  border-radius: vw(470px)
  overflow: hidden
  position: absolute
  border: 2px solid #FF4F12
  display: flex
  align-items: center
  justify-content: center
  left: 0
  right: 0
  top: 0
  bottom: 0
  margin: auto
  @media screen and (max-width: 1000px)
    width: vwm(266px)
    height: vwm(266px)
  img
    width: vw(620px) !important
    height: vw(620px) !important
    max-width: vw(620px) !important
    min-width: vw(620px) !important
    margin-left: vw(-75px) !important
    margin-top: vw(-75px) !important
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    position: absolute
    object-fit: cover
    object-position: center
    @media screen and (max-width: 1000px)
      width: vwm(352px) !important
      height: vwm(352px) !important
      max-width: vwm(352px) !important
      min-width: vwm(352px) !important
      margin-left: vwm(-44px) !important
      margin-top: vwm(-44px) !important
.editor
  width: 100%
  height: 100%
  position: relative
  &__frame
    position: absolute
    width: 100%
    height: 100%
    z-index: 100
    pointer-events: none
    span
      display: block
      position: absolute
      width: vw(128px)
      height: vw(128px)
      background-size: contain
      background-image: url(../assets/img/frame-angle.svg)
      @media screen and (max-width: 1000px)
        width: vwm(72px)
        height: vwm(72px)
      &:nth-child(1)
        transform: rotate(-90deg)
      &:nth-child(2)
        top: 0
        right: 0
      &:nth-child(3)
        transform: rotate(180deg)
        left: 0
        bottom: 0
      &:nth-child(4)
        transform: rotate(90deg)
        right: 0
        bottom: 0
  &__button
    display: flex
    align-items: center
    justify-content: center
    margin-top: vw(-7px)
    @media screen and (max-width: 1000px)
      margin-top: vwm(9px)
    a
      width: vw(220px)
      height: vw(51px)
      background-color: #FF4F12
      text-align: center
      font-family: 'Tektur'
      font-weight: 500
      line-height: vw(51px)
      color: #fff
      font-size: vw(16px)
      letter-spacing: vw(1px)
      border-radius: vw(2px)
      backdrop-filter: blur(12.5px)
      filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
      text-transform: uppercase
      @media screen and (max-width: 1000px)
        width: vwm(206px)
        height: vwm(50px)
        line-height: vwm(50px)
        font-size: vwm(16px)
  &__welcome
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: 50% 50%
    background-image: url(../assets/img/editor-welcome.jpg)
    display: flex
    flex-direction: column
    img
      @media screen and (max-width: 1000px)
        width: 100%
        height: 100%
        object-position: center
        object-fit: cover
    &-title
      font-size:  vw(87.48px)
      color: #fff
      opacity: 0.9
      margin-top: vw(338px)
      font-weight: 500
      font-family: 'Tektur'
      margin-left: vw(77px)
      position: relative
      span
        position: relative
        z-index: 10
      &::after
        content: 'Сила'
        color: #FF4F12
        position: absolute
        left: vw(4px)
        top: vw(4px)
        z-index: 9
        @media screen and (max-width: 1000px)
          left: vwm(3px)
          top: vwm(3px)
      @media screen and (max-width: 1000px)
        font-size: vwm(40px)
        margin-left: vwm(24px)
        margin-top: vwm(207px)
    &-subtitle
      font-size:  vw(87.48px)
      color: #fff
      opacity: 0.9
      margin-top: vw(-43px)
      font-weight: 500
      font-family: 'Tektur'
      margin-left: vw(142px)
      position: relative
      margin-bottom: vw(8px)
      span
        position: relative
        z-index: 10
      &::after
        content: 'команды'
        color: #FF4F12
        position: absolute
        left: vw(4px)
        top: vw(4px)
        z-index: 9
        @media screen and (max-width: 1000px)
          left: vwm(3px)
          top: vwm(3px)
      @media screen and (max-width: 1000px)
        font-size: vwm(40px)
        margin-left: vwm(142px)
        margin-top: vwm(-53px)
</style>
